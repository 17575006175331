@import url(./bootstrap-flex.css);
@import url(./bootstrap-spacing.css);
@import url(./bootstrap-grid-system.css);

@font-face {
  font-family: Roobert;
  src: local(Roobert-Light), local('Roobert Light'),
    url(./fonts/Roobert/Roobert-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Roobert;
  src: local(Roobert-Regular), local('Roobert Regular'),
    local(Roobert),
    url(./fonts/Roobert/Roobert-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Roobert;
  src: local(Roobert-medium), local('Roobert Medium'),
    url(./fonts/Roobert/Roobert-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Roobert;
  src: local(Roobert-SemiBold), local('Roobert SemiBold'),
    url(./fonts/Roobert/Roobert-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Roobert;
  src: local(Roobert-bold), local('Roobert bold'),
    url(./fonts/Roobert/Roobert-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

*,
::after,
::before {
  font-family: Roobert, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}

dd,
dt,
dl,
ul,
ol,
li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.w-100 {
  width: 100% !important;
}

/* some emojis are not correctly displayed with our current fonts */
.emoji-font {
  font-family: sans-serif;
}

:root:has(.html-overflow-hidden) {
  overflow: hidden;
}
